





































import { Component, Vue } from 'vue-property-decorator'
import ThingImage from '@/components/ThingImage/ThingImage.vue'

@Component({ components: { ThingImage } })
export default class AboutPage extends Vue {
  mounted (): void {
    setInterval(this.draw, 1000)
  }

  draw (): void {
    if (this.$refs.what instanceof ThingImage) this.$refs.what.draw()
    if (this.$refs.look instanceof ThingImage) this.$refs.look.draw()
  }
}
